import React from 'react'
import {
  IonContent,
  IonMenu,
  IonIcon,
  IonMenuButton,
  IonButton,
  IonToggle,
  IonItem,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle
} from '@ionic/react'
import {
  call,
  close,
  mail,
  person,
  warningOutline,
  moon,
  business,
  build,
  briefcase
} from 'ionicons/icons'
import './Menu.scss'
import { logout } from 'api/helper'
import { useSelector } from 'react-redux'
import { RootState } from 'stores/reducers'
import { ADMIN_QR, CONTROLLER, getRole } from 'utils/const'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import { useState, useEffect, useRef } from 'react'
import { setDarkTheme, setLightTheme } from 'stores/actions/theme'
import store from 'stores/store'

const Menu: React.FC = (props) => {
  const router = useHistory()
  const user = useSelector((state: RootState) => state.user)
  const role = getRole(user.role)
  const menuRef: any = useRef()
  const theme = useSelector((state: RootState) => state.theme)

  const closeMenu = () => {
    if (menuRef && menuRef.current) {
      menuRef.current.close()
    }
  }

  const goTo = (path: string) => {
    router.push(path)
    closeMenu()
  }

  const ChangeRoleButton = () => {
    if (role !== CONTROLLER || user.controllerRole === '') {
      return null
    } else {
      if (user.controllerRole === CONTROLLER) {
        return (
          <IonButton color="dark" onClick={() => goTo(`/${ADMIN_QR}`)} strong>
            Passer en administrateur
          </IonButton>
        )
      } else if (user.controllerRole === ADMIN_QR) {
        return (
          <IonButton color="dark" onClick={() => goTo(`/${CONTROLLER}`)} strong>
            Passer en contrôleur
          </IonButton>
        )
      }
    }

    return null
  }

  const CurrentRole = () => {
    if (role !== CONTROLLER || user.controllerRole === '') {
      return null
    } else {
      if (user.controllerRole === CONTROLLER) {
        return (
          <div className="info">
            <IonIcon icon={build} color="grey" />
            <div className="info-content">Contrôleur</div>
          </div>
        )
      } else if (user.controllerRole === ADMIN_QR) {
        return (
          <div className="info">
            <IonIcon icon={build} color="grey" />
            <div className="info-content">Administrateur</div>
          </div>
        )
      }
    }

    return null
  }

  const report = () => {
    const fromPath = router.location.pathname
    router.push('/report/reportType', { fromPath })
    closeMenu()
  }

  const willLogout = async () => {
    await logout()
    closeMenu()
  }

  const [dark, setDark] = useState<boolean | undefined>(theme.dark)

  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      setDark(true)
    }
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        setDark(e.matches)
      })
  }, [])

  useEffect(() => {
    if (dark === true) {
      store.dispatch(setDarkTheme())
    } else if (dark === false) {
      store.dispatch(setLightTheme())
    }
  }, [dark])

  const toggleDarkModeHandler = (e: any) => {
    setDark(e.target.checked)
  }

  return (
    <IonMenu id="ion-menu" side="start" contentId="content" ref={menuRef}>
      <IonContent scrollY={false}>
        <IonMenuButton className="menu_button__text">
          <IonIcon icon={close} color="danger"></IonIcon>
          <span className="menu_button__label menu_button__label--danger">
            FERMER
          </span>
        </IonMenuButton>
        <IonItem className="darkItem" lines="none">
          <IonIcon
            slot="start"
            icon={moon}
            className="component-icon component-icon-dark"
          />
          <IonToggle
            style={{ border: 'none !important' }}
            slot="start"
            name="darkMode"
            onIonChange={(e) => toggleDarkModeHandler(e)}
            color="medium"
            checked={dark}
          />
        </IonItem>
        <IonCard className="menu">
          <IonCardHeader>
            <IonCardSubtitle>
              <CurrentRole />
            </IonCardSubtitle>
            <IonCardTitle>
              <IonIcon icon={person} color="dark" />
              <div className="name">
                {user.firstname} {user.lastname}
              </div>
            </IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            {user.phone ? (
              <div className="info">
                <IonIcon icon={call} color="grey" />
                <div className="info-content">{user.phone}</div>
              </div>
            ) : null}
            <div className="info">
              <IonIcon icon={mail} color="grey" />
              <div className="info-content">{user.email}</div>
            </div>
            <div className="info">
              <IonIcon icon={business} color="grey" />
              <div className="info-content">{user.company}</div>
            </div>
            {user.professions.length === 0 ? null : (
              <div className="info">
                <IonIcon icon={briefcase} color="grey" />
                <div>
                  {user.professions.map((profession) => (
                    <div className="info-content" key={profession.id}>
                      {profession.name}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </IonCardContent>
        </IonCard>
        <div className="disconnection">
          <ChangeRoleButton />
          <IonButton color="danger" onClick={report} strong>
            <IonIcon icon={warningOutline}></IonIcon>
            Signaler
          </IonButton>
          <IonButton color="dark" onClick={willLogout} strong>
            <IonIcon src="assets/images/disconnection.svg"></IonIcon>
            Déconnexion
          </IonButton>
        </div>
      </IonContent>
    </IonMenu>
  )
}

export default Menu
