import { axiosApi } from './helper'

export function scoringApi(id, form, signature) {
  return axiosApi('callOut/' + id, { scoring: form, signature }, 'put')
}

export function controlApi(id, form, valid, signature) {
  return axiosApi('control/' + id, { scoring: form, valid, signature }, 'put')
}

export function getSpots() {
  return axiosApi('allSpotsBecauseSpotsIsNotAvailable')
}

// Get the companies list for the signup form
export function companiesList() {
  return axiosApi('companies', {}, 'get')
}

// Get the professions list for the signup form
export function professionsList() {
  return axiosApi('professions', {}, 'get')
}

//
export function signup(object) {
  return axiosApi('account', object, 'post')
}
