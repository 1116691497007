import { BarcodeScanner } from '@ionic-native/barcode-scanner'
import {
  getPlatforms,
  IonButton,
  IonIcon,
  IonSpinner,
  IonToast,
  isPlatform
} from '@ionic/react'
import { getSpotByQrcode } from 'api/spots'
import { PhotoInterface } from 'hooks/usePhotoGallery'
import { Entrance, ReportType } from 'interfaces/common'
import { checkmarkCircle } from 'ionicons/icons'
import Container from 'layouts/containers/Container'
import LayoutDefault from 'layouts/LayoutDefault'
import React, { useEffect, useState } from 'react'
import QrReader from 'react-qr-reader'
import { RouteComponentProps, useHistory } from 'react-router'
import { redirectionHandler, scanHandler } from 'utils/callout'
import '../../components/scan/Scanner/style.scss'

const QrScanner: React.FC<
  RouteComponentProps<
    any,
    any,
    {
      reportType: ReportType
      entrance: Entrance
      message?: string
      photos?: Array<PhotoInterface>
      fromPath: string
    }
  >
> = ({ location }) => {
  const [loading, setloading] = useState(false)
  const [allowScan, setAllowScan] = useState(true)
  const [loadingQrCode, setLoadingQrCode] = useState(true)
  const [error, seterror] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [success, setSuccess] = useState(false)
  const router = useHistory()
  const message = location?.state?.message
  const photos = location?.state?.photos
  const reportType = location?.state?.reportType
  const fromPath = location?.state?.fromPath

  useEffect(() => {
    if (isPlatform('ios') && isPlatform('cordova')) {
      BarcodeScan()
    }
  }, [])

  const BarcodeScan = () => {
    BarcodeScanner.scan({
      prompt: 'Veuillez scanner votre QR code'
    })
      .then((data) => {
        console.log('got data', data.text)
        handleScan(data.text)
        if (data.text === '') {
          router.push('/')
        }
      })
      .catch((data) => {
        console.log('catch : ', data.text)
        router.push('/')
      })
      .finally(() => {
        console.log('finally')
      })
  }

  const handleScan = (data: string | null) => {
    if (!data || loading || error || success || !allowScan) {
      return
    }

    setloading(true)
    seterror(false)
    setAllowScan(false)
    setErrorMsg('')

    if (location.pathname.startsWith('/admin')) {
      // Admin scan
      const qrcode = data.substring(30)
      router.replace(
        location.pathname.substring(location.pathname.length - 5, 0),
        {
          qrcode
        }
      )
      return
    }

    if (location.pathname.startsWith('/report')) {
      // Scan report pour obtenir l'entrée liée au QR code
      const qrcode = data.substring(30)
      getSpotByQrcode(qrcode)
        .then((spot: any) => {
          setSuccess(true)
          setTimeout(() => {
            router.replace('/report/form', {
              spot,
              reportType,
              message,
              photos,
              fromPath
            })
          }, 2000)
        })
        .catch((error: any) => {
          if (error === 'errors.error_network') {
            setErrorMsg(
              "Un problème est survenue, assurez vous d'être connecté à internet"
            )
          } else if (typeof error === 'string') {
            setErrorMsg(error)
          }
          setAllowScan(true)
          seterror(true)
        })
        .finally(() => setloading(false))

      return
    }

    // Intervenant/Controller scan
    scanHandler(data)
      .then((spotID) => {
        setSuccess(true)
        setTimeout(() => {
          redirectionHandler(router, spotID)
        }, 2000)
      })
      .catch((error: any) => {
        if (error === 'errors.error_network') {
          setErrorMsg(
            "Un problème est survenue, assurez vous d'être connecté à internet"
          )
        } else if (error?.data === 'Aucun QRCode correspondant') {
          setErrorMsg("Ce QR Code n'existe pas dans notre base de donnée")
        } else if (error?.data?.message) {
          setErrorMsg(error.data.message)
        } else if (error?.data) {
          setErrorMsg(error.data)
        } else if (typeof error === 'string') {
          setErrorMsg(error)
        }
        seterror(true)
        setAllowScan(true)
        // Afficher l'erreur
      })
      .finally(() => setloading(false))
  }

  const handleError = (err: any) => {
    console.error(err)
  }

  let classname = 'qr_reader'
  classname += loading ? ' loading' : ''
  classname += loadingQrCode ? ' loading-preview' : ''

  if (isPlatform('ios') && isPlatform('cordova')) {
    if (loadingQrCode) {
      setLoadingQrCode(false)
    }

    return (
      <LayoutDefault>
        <div className={classname}>
          <div className="loader">
            <IonSpinner />
          </div>
          {error && (
            <Container center>
              <p className="ion-text-center">
                {errorMsg !== '' ? errorMsg : 'Une erreur est survenue'}
              </p>
              <IonButton onClick={BarcodeScan}>Réessayer</IonButton>
            </Container>
          )}
          {/* <IonToast
            isOpen={error}
            onDidDismiss={() => seterror(false)}
            message={errorMsg !== '' ? errorMsg : 'Une erreur a eu lieu'}
            duration={3000}
            color="danger"
            // buttons={[
            //   {
            //     text: 'Fermer',
            //     role: 'cancel'
            //   }
            // ]}
          />
          <InsToast isOpen={success} message="scan qr code VALIDÉ" /> */}
        </div>
      </LayoutDefault>
    )
  }

  return (
    <LayoutDefault>
      <div className={classname}>
        <div className="loader">
          <IonSpinner />
        </div>
        <div className="indicator">
          <img src="/assets/images/qrcode_viewer.png" alt="" />
          <p>Veuillez scanner votre QR code</p>
        </div>
        <QrReader
          delay={400}
          onError={handleError}
          onScan={handleScan}
          onLoad={() => setLoadingQrCode(false)}
          style={{ width: '100%', height: '100%' }}
          showViewFinder={false}
          // legacyMode={isIosPwa ? true : false}
        />
        <IonToast
          isOpen={error}
          onDidDismiss={() => seterror(false)}
          message={errorMsg !== '' ? errorMsg : 'Une erreur a eu lieu'}
          duration={3000}
          color="danger"
          // buttons={[
          //   {
          //     text: 'Fermer',
          //     role: 'cancel'
          //   }
          // ]}
        />
        <InsToast isOpen={success} message="scan qr code VALIDÉ" />
      </div>
    </LayoutDefault>
  )
}

/**
 * A optimiser // A déplacer si besoin
 */
interface insToastProps {
  isOpen: boolean
  message: string
}
const InsToast: React.FC<insToastProps> = ({ isOpen, message }) => {
  const classes = `ins-toast${isOpen ? ' is-open' : ''}`
  return (
    <div className={classes}>
      <div className="ins-toast__container">
        <div className="ins-toast__icon">
          <IonIcon icon={checkmarkCircle} color="success"></IonIcon>
        </div>
        <div className="ins-toast__message">{message}</div>
      </div>
    </div>
  )
}

export default QrScanner
