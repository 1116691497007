import {
  IonActionSheet,
  IonButton,
  IonCheckbox,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea
} from '@ionic/react'
import Container from 'layouts/containers/Container'
import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import LayoutDefault from 'layouts/LayoutDefault'
import { imageOutline, trash } from 'ionicons/icons'
import { scoringApi, controlApi } from 'api/user'
import { getHigherOrderContacts } from 'api/contact'
import { useSelector } from 'react-redux'
import { RootState } from 'stores/reducers'
import { CONTROLLER, getRole } from 'utils/const'
import store from 'stores/store'
import { delCurrentProfession, delOnGoingScoring } from 'stores/actions/user'
import { getCalloutScoring } from 'api/callout'
import { PhotoInterface, usePhotoGallery } from '../../hooks/usePhotoGallery'
import SignatureCanvas from 'react-signature-canvas'

interface MatchParams {
  calloutID: string
}

interface CheckboxParams {
  name: string
  value?: any
}

interface InputParams {
  type?: string
  name: string
  required: boolean
  index: number
  value?: string
  child_fields?: Array<CheckboxParams>
  files?: any
}

const toBase64 = (file: any): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

type Contact = {
  id: number
  description: string
  name: string
}

const ScoringSubmission: React.FC<
  RouteComponentProps<MatchParams, any, any>
> = ({ match, location }) => {
  const router = useHistory()
  const [form, setform] = useState<InputParams[]>([])
  const [signature, setSignature] = useState(false)
  const [place, setPlace] = useState<string>('')
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState(false)
  const [errorMessage, seterrorMessage] = useState<string | undefined>(
    undefined
  )
  const [phoneerror, setphoneerror] = useState<string | undefined>(undefined)
  const [higherOrderContacts, sethigherOrderContacts] = useState<Contact[]>([])

  const handleChange = (value: any, index: number) => {
    const newForm = form
    newForm[index].value = value
    setform(newForm)
  }

  // Primitive checks
  const validatePhoneNumber = (value: string | undefined) => {
    console.log(value)
    if (!value) {
      return false
    }

    // Remove spaces
    const phoneNumber = value.replace(/\s/g, '')

    console.log(phoneNumber)

    const startsWithLocalDigits = phoneNumber.startsWith('+33')

    if (!startsWithLocalDigits) {
      console.log('ça start pas comme il faut')
      return false
    }

    if (phoneNumber.length !== 12) {
      console.log('c pas assez long')
      return false
    }

    return true
  }

  const handlePhoneChange = (value: any, index: number) => {
    handleChange(value, index)
  }

  const handlePhoneValidation = (value: any, index: number) => {
    setphoneerror(undefined)

    if (!validatePhoneNumber(value)) {
      console.log('c pas bon koi')
      setphoneerror('Le numéro de téléphone renseigné est invalide')
    }
  }

  const state = useSelector((state: RootState) => state)
  const role = getRole(state.user.role)

  const handleCheckChange = (
    value: any,
    index: number,
    child_index: number
  ) => {
    const newForm = form
    newForm[index].child_fields![child_index].value = value
    setform(newForm)
  }

  const handleFiles = async (input: any, index: number) => {
    const newForm = form
    const files = Array.from(input.files)
    const files64: Array<string> = []

    for (const file of files) {
      const base64 = await toBase64(file)
      files64.push(base64)
    }

    newForm[index].files = files64

    // To force react to rerender
    const rerenderNewForm = [...newForm]

    setform(rerenderNewForm)
  }

  const InputText = ({ name, required, index }: InputParams) => {
    return (
      <IonItem>
        <IonLabel position="floating">{name + (required ? ' *' : '')}</IonLabel>
        <IonInput
          placeholder="Votre réponse"
          value={form[index].value}
          onIonChange={(e) => handleChange(e.detail.value!, index)}
        />
      </IonItem>
    )
  }

  const InputPhone = ({ name, required, index }: InputParams) => {
    return (
      <>
        <IonItem>
          <IonLabel position="floating">
            {name + ' (format +33 0 00 00 00 00)' + (required ? ' *' : '')}
          </IonLabel>
          <IonInput
            placeholder="Votre réponse"
            type="tel"
            inputMode="tel"
            value={form[index].value}
            onIonChange={(e) => handlePhoneChange(e.detail.value!, index)}
            onIonBlur={(e) =>
              // @ts-ignore
              handlePhoneValidation(e.detail.currentTarget.value, index)
            }
          />
        </IonItem>
        {/* <div>
          <IonText>
            Le numéro de téléphone doit être au format +33 X XX XX XX XX
          </IonText>
        </div> */}
        {phoneerror && (
          <IonText class="error" color="danger">
            {phoneerror}
          </IonText>
        )}
      </>
    )
  }

  const InputSelect = ({
    name,
    required,
    index,
    child_fields
  }: InputParams) => {
    return (
      <IonItem>
        <IonLabel position="floating">{name + (required ? ' *' : '')}</IonLabel>
        <IonSelect
          placeholder="Votre réponse"
          value={form[index].value}
          onIonChange={(e) => handleChange(e.detail.value!, index)}>
          {child_fields?.map((child, child_index) => (
            <IonSelectOption key={child_index} value={child.value}>
              {child.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const InputTextarea = ({ name, required, index }: InputParams) => {
    return (
      <IonItem>
        <IonLabel position="floating">{name + (required ? ' *' : '')}</IonLabel>
        <IonTextarea
          autoGrow
          placeholder="Votre réponse"
          value={form[index].value}
          onIonChange={(e) => handleChange(e.detail.value!, index)}
        />
      </IonItem>
    )
  }

  const InputCheckbox = ({
    name,
    required,
    index,
    child_fields
  }: InputParams) => {
    return (
      <div className="vertical-margin-20">
        <IonItem lines="none">
          <IonLabel>{name + (required ? ' *' : '')}</IonLabel>
        </IonItem>
        <IonList>
          {child_fields?.map((child, child_index) => (
            <IonItem key={child_index}>
              <IonLabel>{child.name}</IonLabel>
              <IonCheckbox
                checked={child.value}
                onIonChange={(e) =>
                  handleCheckChange(e.detail.checked, index, child_index)
                }
              />
            </IonItem>
          ))}
        </IonList>
      </div>
    )
  }

  const InputRadio = ({
    name,
    required,
    index,
    child_fields,
    value
  }: InputParams) => {
    return (
      <div className="vertical-margin-20">
        <IonItem lines="none">
          <IonLabel>{name + (required ? ' *' : '')}</IonLabel>
        </IonItem>
        <IonList>
          {/* C'est la IonRadioGroup qui contient le state du choix du champ */}
          <IonRadioGroup
            value={value}
            onIonChange={(e) => handleChange(e.detail.value, index)}>
            {child_fields?.map((child, child_index) => (
              <IonItem key={child_index}>
                <IonLabel>{child.name}</IonLabel>
                <IonRadio value={child} />
              </IonItem>
            ))}
          </IonRadioGroup>
        </IonList>
      </div>
    )
  }

  const InputPhoto = ({ name, required, index, files }: InputParams) => {
    const fileInput = useRef(null)

    const { photos, takePhoto, deletePhoto, setPhotos } = usePhotoGallery()
    useEffect(() => {
      setPhotos(files)
    }, [files, setPhotos])

    const [photoToDelete, setPhotoToDelete] = useState<PhotoInterface>()

    // on met à jour les photos dans le form
    form[index].files = photos

    return (
      <div className="vertical-margin-20">
        <IonItem lines="none">
          <IonLabel>{name + (required ? ' *' : '')}</IonLabel>
        </IonItem>
        <IonItem>
          <input
            ref={fileInput}
            hidden
            multiple
            type="file"
            accept="image/*"
            onChange={(e) => handleFiles(e.nativeEvent.target, index)}
          />
          <IonButton
            color="light"
            onClick={() => {
              // @ts-ignore
              // fileInput?.current?.click()
              takePhoto()
            }}>
            <IonIcon slot="start" icon={imageOutline} />
            Ajouter une image
          </IonButton>
        </IonItem>

        {photos.length > 0 ? (
          <div className="list-img">
            <IonItem lines="none">
              <div>
                {photos.map((photo, index) => (
                  <div key={index} className="image-delete-wrapper">
                    <IonImg
                      src={photo.webviewPath}
                      onClick={() => setPhotoToDelete(photo)}
                      className="ion-margin-vertical"
                    />
                    <span className="image-delete"></span>
                  </div>
                ))}
              </div>
            </IonItem>
          </div>
        ) : null}

        <IonActionSheet
          isOpen={!!photoToDelete}
          buttons={[
            {
              text: 'Effacer',
              role: 'destructive',
              icon: trash,
              handler: () => {
                if (photoToDelete) {
                  deletePhoto(photoToDelete)
                  setPhotoToDelete(undefined)
                }
              }
            }
          ]}
          onDidDismiss={() => setPhotoToDelete(undefined)}
        />
      </div>
    )
  }

  const Input = ({ input, index }: { input: InputParams; index: number }) => {
    switch (input.type) {
      case 'text':
        return (
          <InputText
            key={index}
            name={input.name}
            required={input.required}
            index={index}
          />
        )

      case 'order_number':
        return (
          <InputText
            key={index}
            name="Numéro du bon de commande"
            required
            index={index}
          />
        )

      case 'reclamation_number':
        return (
          <InputText
            key={index}
            name="Bon de réclamation"
            required
            index={index}
          />
        )

      case 'textarea':
        return (
          <InputTextarea
            key={index}
            name={input.name}
            required={input.required}
            index={index}
          />
        )

      case 'checkbox':
        return (
          <InputCheckbox
            key={index}
            name={input.name}
            required={input.required}
            index={index}
            child_fields={input.child_fields}
          />
        )

      case 'radio':
        return (
          <InputRadio
            key={index}
            name={input.name}
            required={input.required}
            index={index}
            child_fields={input.child_fields}
            value={input.value}
          />
        )

      case 'photo':
        return (
          <InputPhoto
            key={index}
            name={input.name}
            required={input.required}
            index={index}
            child_fields={input.child_fields}
            value={input.value}
            files={input.files || []}
          />
        )

      case 'status':
        return (
          <InputRadio
            key={index}
            name={"Status de l'intervention"}
            required
            index={index}
            child_fields={[
              { value: 'finished', name: 'Réalisée' },
              { value: 'partial', name: 'Partiellement réalisée' },
              { value: 'ko', name: 'Non réalisée' }
            ]}
            value={input.value}
          />
        )

      case 'client_phone':
        return (
          <InputPhone
            key={index}
            name="Numéro de téléphone client"
            required
            index={index}
          />
        )

      case 'higherOrder':
        return (
          <InputSelect
            key={index}
            name={'Service commanditaire'}
            required
            index={index}
            child_fields={higherOrderContacts.map((contact) => {
              return {
                value: contact.id,
                name: contact.name
              }
            })}
            value={input.value}
          />
        )

      default:
        return <></>
    }
  }

  // const displayForm = useMemo(() => {
  //   return form.map((input, index) => {
  //     switch (input.type) {
  //       case 'text':
  //         return (
  //           <InputText
  //             key={index}
  //             name={input.name}
  //             required={input.required}
  //             index={index}
  //           />
  //         )

  //       case 'textarea':
  //         return (
  //           <InputTextarea
  //             key={index}
  //             name={input.name}
  //             required={input.required}
  //             index={index}
  //           />
  //         )

  //       case 'checkbox':
  //         return (
  //           <InputCheckbox
  //             key={index}
  //             name={input.name}
  //             required={input.required}
  //             index={index}
  //             child_fields={input.child_fields}
  //           />
  //         )

  //       case 'radio':
  //         return (
  //           <InputRadio
  //             key={index}
  //             name={input.name}
  //             required={input.required}
  //             index={index}
  //             child_fields={input.child_fields}
  //             value={input.value}
  //           />
  //         )

  //       case 'photo':
  //         return (
  //           <InputPhoto
  //             key={index}
  //             name={input.name}
  //             required={input.required}
  //             index={index}
  //             child_fields={input.child_fields}
  //             value={input.value}
  //             files={input.files || []}
  //           />
  //         )

  //       case 'status':
  //         return (
  //           <InputRadio
  //             key={index}
  //             name={"Status de l'intervention"}
  //             required
  //             index={index}
  //             child_fields={[
  //               { value: 'finished', name: 'Terminée' },
  //               { value: 'partial', name: 'Partiellement terminée' },
  //               { value: 'ko', name: 'KO' }
  //             ]}
  //             value={input.value}
  //           />
  //         )

  //       case 'client_phone':
  //         return (
  //           <InputPhone
  //             key={index}
  //             name="Numéro de téléphone client"
  //             required
  //             index={index}
  //           />
  //         )

  //       case 'higherOrder':
  //         return (
  //           <InputSelect
  //             key={index}
  //             name={'Service commanditaire'}
  //             required
  //             index={index}
  //             child_fields={higherOrderContacts.map((contact) => {
  //               return {
  //                 value: contact.id,
  //                 name: contact.name
  //               }
  //             })}
  //             value={input.value}
  //           />
  //         )

  //       default:
  //         return ''
  //     }
  //   })
  // }, [form, phoneerror])

  const submit = () => {
    if (role === CONTROLLER) {
      submitController()
    } else {
      submitIntervenant()
    }
  }

  const submitIntervenant = () => {
    setloading(true)
    seterror(false)

    let signatureCanvas
    // vérif bouton radio et signature
    if (signature) {
      if (sigDraw) {
        //@ts-ignore
        signatureCanvas = sigRef.current.getTrimmedCanvas().toDataURL()
      } else {
        setloading(false)
        return false
      }
    }

    scoringApi(match.params.calloutID, form, signatureCanvas)
      .then(() => {
        store.dispatch(delCurrentProfession())
        store.dispatch(delOnGoingScoring())
        // Redirect
        router.push(`/${role}`)
      })
      .catch(() => {
        seterror(true)
        setloading(false)
      })
  }

  const submitController = () => {
    setloading(true)
    seterror(false)

    let signatureCanvas, valid
    // vérif bouton radio et signature
    if (typeof validControl === 'boolean') {
      if (signature) {
        if (sigDraw) {
          //@ts-ignore
          signatureCanvas = sigRef.current.getTrimmedCanvas().toDataURL()
        } else {
          setloading(false)
          return false
        }
      }

      valid = validControl
    } else {
      setloading(false)
      return false
    }

    controlApi(match.params.calloutID, form, valid, signatureCanvas)
      .then(() => {
        store.dispatch(delCurrentProfession())
        store.dispatch(delOnGoingScoring())
        // Redirect
        router.push(`/${role}`)
      })
      .catch(() => {
        seterror(true)
        setloading(false)
      })
  }

  useEffect(() => {
    // si le formulaire existe déjà, pas besoin de le réinit
    if (!form.length) {
      // Init radio inputs
      if (location.state && location.state.form && location.state.place) {
        location.state.form.forEach((input: InputParams, index: number) => {
          if (input.type === 'radio') {
            location.state.form[index].value = input.child_fields![0]?.name
          }
        })
        setform(location.state.form)
        setPlace(location.state.place)
      } else {
        // Si le form n'est pas dans les params on le récupère par api
        getCalloutScoring(match.params.calloutID).then((data: any) => {
          data.form.forEach((input: InputParams, index: number) => {
            if (input.type === 'radio') {
              data.form[index].value = input.child_fields![0]?.name
            }
          })
          setform(data.form)
          setPlace(data.place)
          setSignature(data.signatureRequired)
        })
      }
    }
  }, [match.params, location, form])

  useEffect(() => {
    // @ts-ignore
    getHigherOrderContacts().then(sethigherOrderContacts)
  }, [])

  // if (error) {
  //   return (
  //     <Container center>
  //       <p className="ion-text-center">Une erreur a eu lieu</p>
  //       <IonButton onClick={fetchJobs}>Réessayer</IonButton>
  //     </Container>
  //   )
  // }

  // RENDU SIGNATURE ELECTRONIQUE
  const sigWrapperRef = useRef<HTMLDivElement>(null)
  const sigRef = useRef(null)
  const [canW, setCanW] = useState(0)
  // const [penColor, setPenColor] = useState('')
  const [sigDraw, setSigDraw] = useState(false)
  useEffect(() => {
    //setTimeout pour être sur que la div est bien rendue
    setTimeout(() => {
      // @ts-ignore
      let width = sigWrapperRef?.current
        ? sigWrapperRef?.current?.offsetWidth
        : window.innerWidth - 60

      // @ts-ignore
      // let color = sigWrapperRef.current ? window.getComputedStyle(sigWrapperRef.current).color : ''
      setCanW(width)
      // console.log(window.innerWidth);
      // setPenColor(color);
    }, 500)
  }, [canW])

  const renderElectronicSignature = () => {
    if (!signature) {
      return null
    }

    const onEnd = () => {
      setSigDraw(true)
    }
    const clear = () => {
      setSigDraw(false)
      //@ts-ignore
      sigRef.current.clear()
    }
    return (
      <div ref={sigWrapperRef} className="sig-wrapper">
        {sigDraw && (
          <div className="sig-clear" onClick={clear}>
            <IonIcon icon={trash} />
            <span>effacer</span>
          </div>
        )}
        <IonItem lines="none">
          <IonLabel>Signature électronique * :</IonLabel>
        </IonItem>
        {canW > 0 ? (
          <SignatureCanvas
            backgroundColor="rgba(255,255,255)"
            ref={sigRef}
            canvasProps={{ width: canW, height: 200, className: 'sig-canvas' }}
            onEnd={onEnd}
          />
        ) : null}
      </div>
    )
  }

  // RENDU CONTROLE CONFORME

  const [validControl, setValidControl] = useState()

  const renderCompliantControl = useMemo(() => {
    if (role !== CONTROLLER) {
      return null
    }

    return (
      <div className="vertical-margin-20">
        <IonItem lines="none" id="conforme">
          <IonLabel>Contrôle conforme ? *</IonLabel>
        </IonItem>
        <IonList>
          <IonRadioGroup
            value={validControl}
            onIonChange={(e) => setValidControl(e.detail.value)}>
            <IonItem>
              <IonLabel>Oui</IonLabel>
              <IonRadio value={true} />
            </IonItem>
            <IonItem>
              <IonLabel>Non</IonLabel>
              <IonRadio value={false} />
            </IonItem>
          </IonRadioGroup>
        </IonList>
      </div>
    )
  }, [validControl, role])

  const _submit = (event: FormEvent) => {
    seterrorMessage(undefined)
    event.preventDefault()

    const missingFields = form.some((input) => {
      if (input.required && !input.value) {
        return true
      }
    })

    if (missingFields) {
      seterrorMessage('Veuillez renseigner tous les champs obligatoire')
      return
    }

    submit()
  }

  return (
    <LayoutDefault>
      <Container paddingTop paddingBottom loading={loading}>
        {place && (
          <h3>
            Veuillez remplir ce formulaire pour l'intervention: <br />
            {place}
          </h3>
        )}
        <form onSubmit={_submit}>
          {form.map((input, index) => (
            <Input input={input} index={index} key={index} />
          ))}
          {renderCompliantControl}
          {renderElectronicSignature()}
          {errorMessage && (
            <div className="ion-margin-top">
              <IonText class="error" color="danger">
                {errorMessage}
              </IonText>
            </div>
          )}
          <IonButton className="ion-margin-top full" type="submit">
            Envoyer
          </IonButton>
        </form>
      </Container>
    </LayoutDefault>
  )
}

export default ScoringSubmission
